export const DISPLAY_COMPONENT = {
  inGameMorning: "Morning",
  inGameConfigProfile: "ConfigProfile",
  hostComponent: "hostComponent",
  joinComponent: "joinComponent",
  home: "home",
  waitRoomComponent: "waitRoomComponent",
  configProfile: "configProfile",
  error: "error",
  renderStatusId: "renderStatusId",
  finishGame: "finishGame",
};
